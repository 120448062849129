<template>
  <div>
    <search-traffic-pool-sim-card
      @submit="submitSearch"
    />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      row-key="iccid"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import { findTrafficPoolSimCards, exportTrafficPoolSimCards } from '@/api/traffic_pool'
import Pagination from '@/components/Pagination'
import SearchTrafficPoolSimCard from '@/views/traffic_pools/sim_card/Search'
import { exportExcel } from '@/api/excel'

export default {
  name: 'TrafficPoolSimCardList',
  components: {
    SearchTrafficPoolSimCard,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '运营商状态',
          dataIndex: 'card_status'
        },
        {
          title: '生命周期',
          dataIndex: 'life_cycle'
        },
        {
          title: '联网状态',
          dataIndex: 'net_status'
        },
        {
          title: '入池状态',
          dataIndex: 'is_into_pool'
        },
        {
          title: '划拨对象',
          dataIndex: 'agent_name'
        },
        {
          title: '服务起始时间',
          dataIndex: 'activated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'activated_at' ? this.sort.sort_order : false
        },
        {
          title: '服务结束时间',
          dataIndex: 'service_end_time',
          sorter: true,
          sortOrder: this.sort.sort_field === 'service_end_time' ? this.sort.sort_order : false
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '本周期用量',
          dataIndex: 'cycle_usage',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cycle_usage' ? this.sort.sort_order : false
        },
        {
          title: '卡号限额',
          dataIndex: 'limit_usage_value',
          sorter: true,
          sortOrder: this.sort.sort_field === 'limit_usage_value' ? this.sort.sort_order : false
        },
        {
          title: '数据更新时间',
          dataIndex: 'data_updated_at'
        }
      ]
    },

    trafficPoolId() {
      return parseInt(this.$route.params.traffic_pool_id)
    }
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTrafficPoolSimCards(this.trafficPoolId, Object.assign({},
        this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    exportData() {
      exportTrafficPoolSimCards(this.trafficPoolId, Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
